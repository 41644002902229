import { FlagInfo, flags } from './data/flags';

export function getSimilarFlags(similarToFlag: FlagInfo, count: number) {
    const calculateSimilarity = (flag1: FlagInfo, flag2: FlagInfo) => {
        // based on the tags
        let similarity = 0;
        flag1.tags.forEach(tag1 => {
            if (flag2.tags.includes(tag1)) {
                similarity++;
            }
        });
        return similarity;
    };

    let similarFlags = flags
        .filter(flag => flag !== similarToFlag)
        .map(flag => {
            return {
                ...flag,
                similarity: calculateSimilarity(similarToFlag, flag)
            };
        })
        .sort((flag1, flag2) => flag2.similarity - flag1.similarity)
        .slice(0, count * 2);

    return similarFlags = similarFlags
        .sort(() => Math.random() - 0.5)
        .slice(0, count);
}
