import { Box, Button, Card, CardActionArea, CardMedia, Typography } from '@mui/material';
import { FlagInfo, flags } from './data/flags';
import styles from './App.module.scss';
import { useState } from 'react';
import { Check, Close } from '@mui/icons-material';
import { getSimilarFlags } from './getSimilarFlags';


export function SelectTheCountryGame() {
    const [flagsToShow, setFlagsToShow] = useState<FlagInfo[]>([]);
    const [clickedFlag, setClickedFlag] = useState<FlagInfo | null>(null);
    const [gameStarted, setGameStarted] = useState(false);
    const [gameFinished, setGameFinished] = useState(false);
    const [shuffledFlags, setShuffledFlags] = useState<FlagInfo[]>(flags);
    const [flagIndex, setFlagIndex] = useState<number | null>(null);
    const [score, setScore] = useState(0);

    const next = (shuffledFlags: FlagInfo[], flagIndex: number | null) => {
        setClickedFlag(null);

        const newFlagIndex = (flagIndex ?? -1) + 1;
        if (newFlagIndex >= shuffledFlags.length) {
            setGameFinished(true);
            return;
        }

        const selectedFlag = shuffledFlags[newFlagIndex];

        const flagsToShow: FlagInfo[] = [...getSimilarFlags(selectedFlag, 5)];
        const insertAt = Math.floor(Math.random() * (flagsToShow.length + 1));
        flagsToShow.splice(insertAt, 0, selectedFlag);

        setFlagIndex(newFlagIndex);
        setFlagsToShow(flagsToShow);
    };

    const selectAnswer = (flag: FlagInfo) => {
        if (clickedFlag !== null) {
            return;
        }

        setClickedFlag(flag);

        let ms;
        if (flag === shuffledFlags[flagIndex]) {
            setScore(score + 1);
            ms = 500;
        } else {
            ms = 1000;
        }

        setTimeout(() => {
            next(shuffledFlags, flagIndex);
        }, ms);
    };

    const shuffleFlags = () => {
        const shuffledFlags = [...flags];
        shuffledFlags.sort(() => Math.random() - 0.5);

        setShuffledFlags(shuffledFlags);

        return shuffledFlags;
    };

    const startGame = () => {
        setGameFinished(false);
        setScore(0);
        const shuffledFlags = shuffleFlags();
        setFlagIndex(null);
        setGameStarted(true);
        next(shuffledFlags, null);
    };

    const skip = () => {
        if (flagIndex !== null && flagIndex < shuffledFlags.length - 1) {
            const skippedFlag = shuffledFlags.splice(flagIndex, 1)[0];
            shuffledFlags.push(skippedFlag);
            setShuffledFlags(shuffledFlags);
        }
        next(shuffledFlags, flagIndex - 1);
    };

    if (!gameStarted) {
        return <Box>
            <Typography variant="h2" className={styles.subtitle}>Select the country</Typography>

            <Box className={styles.controls}>
                <Button variant="contained" color="primary" onClick={() => startGame()}>Start Game</Button>
            </Box>
        </Box>;
    }

    if (gameFinished) {
        return <Box>
            <Typography variant="h2" className={styles.subtitle}>Select the country</Typography>

            <Typography variant="h2" className={styles.flagName}>Game Finished</Typography>

            <Typography variant="h2" className={styles.flagName}>Score: {score} out of {shuffledFlags.length}</Typography>

            <Box className={styles.controls}>
                <Button variant="contained" color="primary" onClick={() => startGame()}>Start New Game</Button>
            </Box>
        </Box>;
    }

    if (flagIndex === null) {
        return null;
    }

    return <Box className={styles.selectTheCountryGame}>
        <Typography variant="h2" className={styles.subtitle}>Select the country</Typography>

        <Typography variant="h2" className={styles.flagName}>{flagIndex + 1} / {shuffledFlags.length} - Score: {score}</Typography>

        <Box className={styles.singleFlagContainer}>
            <Card className={styles.flag} elevation={5}>
                <CardMedia component="img" image={shuffledFlags[flagIndex].svgUrl.href} />
            </Card>
        </Box>

        <Box className={styles.countriesContainer}>
            {flagsToShow.map((flag, index) => {
                return <Card key={index} className={styles.country} elevation={5}>
                    <CardActionArea className={styles.countryActionArea} onClick={() => selectAnswer(flag)} disableRipple>
                        <Typography variant="h6">{flag.country}</Typography>
                        {clickedFlag === flag && <Box className={styles.clickedFlagText}>
                            {flag === shuffledFlags[flagIndex] ?
                                (
                                    <Typography variant="h6" className={styles.correctAnswer}>
                                        <Check />
                                        Correct
                                    </Typography>
                                ) : (
                                    <Typography variant="h6" className={styles.incorrectAnswer}>
                                        <Close />
                                        Incorrect
                                    </Typography>
                                )}
                        </Box>}
                    </CardActionArea>
                </Card>;
            })}
        </Box>

        <Box className={styles.controls}>
            <Button variant="contained" color="primary" onClick={() => { skip(); }}>Skip</Button>
        </Box>
    </Box>;
}
