import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { flags } from './data/flags';
import styles from './App.module.scss';


export function ViewAllFlagsGame() {

    return <Box>
        <Typography variant="h2" className={styles.subtitle}>View all flags</Typography>

        <Box className={styles.flagsContainer}>
            {flags.map((flag, index) => {
                return <Card key={index} className={styles.flag} elevation={5}>
                    <CardMedia component="img" image={flag.svgUrl.href} />
                    <CardContent>
                        <Typography variant="h6" className={styles.flagName}>{flag.country}</Typography>
                    </CardContent>
                </Card>;
            })}
        </Box>
    </Box>;
}
