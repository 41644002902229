import {
    BrowserRouter, Route, Routes, useLocation, useNavigate
} from 'react-router-dom';
import { AppBar, Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, ThemeProvider, Toolbar, Typography, createTheme } from '@mui/material';
import { SelectTheFlagGame } from './SelectTheFlagGame';
import { SelectTheCountryGame } from './SelectTheCountryGame';
import { ViewAllFlagsGame } from './ViewAllFlagsGame';
import styles from './App.module.scss';
import { ArrowLeft, ChevronLeft } from '@mui/icons-material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#dddddd',
        },
    },

});

export function App() {



    return <ThemeProvider theme={theme}>
        <Box>
            <BrowserRouter>
                <FlagsAppBar />
                <Routes>
                    <Route path="/select-the-flag" element={<SelectTheFlagGame />} />
                    <Route path="/select-the-country" element={<SelectTheCountryGame />} />
                    <Route path="/view-all-flags" element={<ViewAllFlagsGame />} />
                    <Route path="/" element={<GameSelector />} />
                </Routes>
            </BrowserRouter>
        </Box>
    </ThemeProvider>
}

function FlagsAppBar() {

    const navigate = useNavigate();
    const location = useLocation();
    const showArrow = location.pathname !== '/';


    return <AppBar position="sticky" className={styles.appBar}>
        <Toolbar>
            {showArrow && <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => navigate('/')}
                sx={{ mr: 2 }}
            >
                <ChevronLeft />
            </IconButton>}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Flags
            </Typography>
        </Toolbar>
    </AppBar>
}

function GameSelector() {

    const navigate = useNavigate();

    return <Box>
        <Typography variant="h2" className={styles.subtitle}>Games</Typography>
        <Box component="nav" className={styles.gamesNav}>
            <Button variant="contained" onClick={() => navigate('/select-the-flag')}>
                Select the flag
            </Button>
            <Button variant="contained" onClick={() => navigate('/select-the-country')}>
                Select the country
            </Button>
            <Button variant="contained" onClick={() => navigate('/view-all-flags')}>
                View all flags
            </Button>
        </Box>
    </Box>
}