import { createRoot } from 'react-dom/client';
import { App } from './App';

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(<App />);

navigator.serviceWorker.register(
    new URL('service-worker.js', import.meta.url),
    {type: 'module'}
  );