.flagsContainer, .countriesContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 1rem;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 0 1rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
    }
}

.country {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 4.5rem;
}

.countryActionArea {
    padding: 1rem 2rem !important;

    h6 {
        line-height: 1;
    }
}

h1.title {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
}

h2.flagName, h2.subtitle {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
}

.controls {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.clickable {
    cursor: pointer;
}

.flag {
    position: relative;
}

.flagName {
    text-align: center;
}

.clickedFlagText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    text-align: center;
    background-color: rgba(255, 255, 255, 0.75);

    h6 {
        line-height: 1 !important;
    }
}

.correctAnswer, .incorrectAnswer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
        width: 2rem;
        height: 2rem;
    }
}

.correctAnswer {
    color: green;
}

.incorrectAnswer {
    color: red;
}

.gamesNav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.appBar {
    margin-bottom: 2rem;
}

.singleFlagContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto auto 1rem auto;
    width: 50%;
    max-width: 600px;

    img {
        width: 100%;
        max-width: 600px;
    }

    @media (min-width: 768px) {
        width: 100%;
    }

}