export type FlagInfo = {
    country: string;
    countryCode: string;
    tags: string[];
    svgUrl: URL;
}

export const flags: FlagInfo[] = [
    {
        country: "Afghanistan",
        countryCode: "AF",
        tags: ["Black", "Red", "Green", "Vertical Stripes", "Asia", "Emblem"],
        svgUrl: new URL('../images/flags/af.svg', import.meta.url)
    },
    {
        country: "Albania",
        countryCode: "AL",
        tags: ["Red", "Black", "Eagle", "Europe"],
        svgUrl: new URL('../images/flags/al.svg', import.meta.url)
    },
    {
        country: "Algeria",
        countryCode: "DZ",
        tags: ["Green", "White", "Red", "Crescent", "Star", "Africa"],
        svgUrl: new URL('../images/flags/dz.svg', import.meta.url)
    },
    {
        country: "Andorra",
        countryCode: "AD",
        tags: ["Blue", "Yellow", "Red", "Coat of Arms", "Europe"],
        svgUrl: new URL('../images/flags/ad.svg', import.meta.url)
    },
    {
        country: "Angola",
        countryCode: "AO",
        tags: ["Red", "Black", "Gear", "Machete", "Star", "Africa"],
        svgUrl: new URL('../images/flags/ao.svg', import.meta.url)
    },
    {
        country: "Antigua and Barbuda",
        countryCode: "AG",
        tags: ["Red", "Black", "Blue", "Yellow", "Sun", "America"],
        svgUrl: new URL('../images/flags/ag.svg', import.meta.url)
    },
    {
        country: "Argentina",
        countryCode: "AR",
        tags: ["Light Blue", "Blue", "Blue", "White", "Sun", "America"],
        svgUrl: new URL('../images/flags/ar.svg', import.meta.url)
    },
    {
        country: "Armenia",
        countryCode: "AM",
        tags: ["Red", "Blue", "Orange", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/am.svg', import.meta.url)
    },
    {
        country: "Australia",
        countryCode: "AU",
        tags: ["Blue", "White", "Stars", "Union Jack", "Oceania"],
        svgUrl: new URL('../images/flags/au.svg', import.meta.url)
    },
    {
        country: "Austria",
        countryCode: "AT",
        tags: ["Red", "White", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/at.svg', import.meta.url)
    },
    {
        country: "Azerbaijan",
        countryCode: "AZ",
        tags: ["Blue", "Red", "Green", "Crescent", "Star", "Asia"],
        svgUrl: new URL('../images/flags/az.svg', import.meta.url)
    },
    {
        country: "Bahamas",
        countryCode: "BS",
        tags: ["Black", "Yellow", "Blue", "Triangle", "America"],
        svgUrl: new URL('../images/flags/bs.svg', import.meta.url)
    },
    {
        country: "Bahrain",
        countryCode: "BH",
        tags: ["Red", "White", "Five-point design", "Asia"],
        svgUrl: new URL('../images/flags/bh.svg', import.meta.url)
    },
    {
        country: "Bangladesh",
        countryCode: "BD",
        tags: ["Green", "Red", "Circle", "Asia"],
        svgUrl: new URL('../images/flags/bd.svg', import.meta.url)
    },
    {
        country: "Barbados",
        countryCode: "BB",
        tags: ["Blue", "Yellow", "Black", "Trident", "America"],
        svgUrl: new URL('../images/flags/bb.svg', import.meta.url)
    },
    {
        country: "Belarus",
        countryCode: "BY",
        tags: ["Red", "Green", "White", "Ornament", "Europe"],
        svgUrl: new URL('../images/flags/by.svg', import.meta.url)
    },
    {
        country: "Belgium",
        countryCode: "BE",
        tags: ["Black", "Yellow", "Red", "Vertical Stripes", "Europe"],
        svgUrl: new URL('../images/flags/be.svg', import.meta.url)
    },
    {
        country: "Belize",
        countryCode: "BZ",
        tags: ["Blue", "Red", "White", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/bz.svg', import.meta.url)
    },
    {
        country: "Benin",
        countryCode: "BJ",
        tags: ["Green", "Yellow", "Red", "Vertical and Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/bj.svg', import.meta.url)
    },
    {
        country: "Bhutan",
        countryCode: "BT",
        tags: ["Orange", "Yellow", "Dragon", "Asia"],
        svgUrl: new URL('../images/flags/bt.svg', import.meta.url)
    },
    {
        country: "Bolivia",
        countryCode: "BO",
        tags: ["Red", "Yellow", "Green", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/bo.svg', import.meta.url)
    },
    {
        country: "Bosnia and Herzegovina",
        countryCode: "BA",
        tags: ["Blue", "Yellow", "White", "Stars", "Triangle", "Europe"],
        svgUrl: new URL('../images/flags/ba.svg', import.meta.url)
    },
    {
        country: "Botswana",
        countryCode: "BW",
        tags: ["Blue", "Black", "White", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/bw.svg', import.meta.url)
    },
    {
        country: "Brazil",
        countryCode: "BR",
        tags: ["Green", "Yellow", "Blue", "White", "Stars", "Globe", "America"],
        svgUrl: new URL('../images/flags/br.svg', import.meta.url)
    },
    {
        country: "Brunei",
        countryCode: "BN",
        tags: ["Yellow", "White", "Black", "Crescent", "Hands", "Umbrella", "Asia"],
        svgUrl: new URL('../images/flags/bn.svg', import.meta.url)
    },
    {
        country: "Bulgaria",
        countryCode: "BG",
        tags: ["White", "Green", "Red", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/bg.svg', import.meta.url)
    },
    {
        country: "Burkina Faso",
        countryCode: "BF",
        tags: ["Red", "Green", "Yellow", "Star", "Africa"],
        svgUrl: new URL('../images/flags/bf.svg', import.meta.url)
    },
    {
        country: "Burundi",
        countryCode: "BI",
        tags: ["Red", "Green", "White", "Stars", "Diagonal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/bi.svg', import.meta.url)
    },
    {
        country: "Cabo Verde",
        countryCode: "CV",
        tags: ["Blue", "White", "Red", "Stripes", "Stars", "Africa"],
        svgUrl: new URL('../images/flags/cv.svg', import.meta.url)
    },
    {
        country: "Cambodia",
        countryCode: "KH",
        tags: ["Blue", "Red", "White", "Temple", "Asia"],
        svgUrl: new URL('../images/flags/kh.svg', import.meta.url)
    },
    {
        country: "Cameroon",
        countryCode: "CM",
        tags: ["Green", "Red", "Yellow", "Star", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/cm.svg', import.meta.url)
    },
    {
        country: "Canada",
        countryCode: "CA",
        tags: ["Red", "White", "Maple Leaf", "North America"],
        svgUrl: new URL('../images/flags/ca.svg', import.meta.url)
    },
    {
        country: "Central African Republic",
        countryCode: "CF",
        tags: ["Blue", "White", "Green", "Yellow", "Red", "Star", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/cf.svg', import.meta.url)
    },
    {
        country: "Chad",
        countryCode: "TD",
        tags: ["Blue", "Yellow", "Red", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/td.svg', import.meta.url)
    },
    {
        country: "Chile",
        countryCode: "CL",
        tags: ["Red", "White", "Blue", "Star", "America"],
        svgUrl: new URL('../images/flags/cl.svg', import.meta.url)
    },
    {
        country: "China",
        countryCode: "CN",
        tags: ["Red", "Yellow", "Stars", "Asia"],
        svgUrl: new URL('../images/flags/cn.svg', import.meta.url)
    },
    {
        country: "Colombia",
        countryCode: "CO",
        tags: ["Yellow", "Blue", "Red", "Horizontal Stripes", "America"],
        svgUrl: new URL('../images/flags/co.svg', import.meta.url)
    },
    {
        country: "Comoros",
        countryCode: "KM",
        tags: ["Green", "White", "Red", "Blue", "Crescent", "Stars", "Africa"],
        svgUrl: new URL('../images/flags/km.svg', import.meta.url)
    },
    {
        country: "Costa Rica",
        countryCode: "CR",
        tags: ["Red", "White", "Blue", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/cr.svg', import.meta.url)
    },
    {
        country: "Côte d'Ivoire",
        countryCode: "CI",
        tags: ["Orange", "White", "Green", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/ci.svg', import.meta.url)
    },
    {
        country: "Croatia",
        countryCode: "HR",
        tags: ["Red", "White", "Blue", "Coat of Arms", "Europe"],
        svgUrl: new URL('../images/flags/hr.svg', import.meta.url)
    },
    {
        country: "Cuba",
        countryCode: "CU",
        tags: ["Blue", "White", "Red", "Triangle", "Star", "America"],
        svgUrl: new URL('../images/flags/cu.svg', import.meta.url)
    },
    {
        country: "Cyprus",
        countryCode: "CY",
        tags: ["White", "Orange", "Olive Branches", "Europe"],
        svgUrl: new URL('../images/flags/cy.svg', import.meta.url)
    },
    {
        country: "Czechia",
        countryCode: "CZ",
        tags: ["Red", "White", "Blue", "Triangle", "Europe"],
        svgUrl: new URL('../images/flags/cz.svg', import.meta.url)
    },
    {
        country: "Democratic Republic of the Congo",
        countryCode: "CD",
        tags: ["Blue", "Red", "Yellow", "Star", "Africa"],
        svgUrl: new URL('../images/flags/cd.svg', import.meta.url)
    },
    {
        country: "Denmark",
        countryCode: "DK",
        tags: ["Red", "White", "Scandinavian Cross", "Europe"],
        svgUrl: new URL('../images/flags/dk.svg', import.meta.url)
    },
    {
        country: "Djibouti",
        countryCode: "DJ",
        tags: ["Blue", "Green", "White", "Star", "Red", "Triangle", "Africa"],
        svgUrl: new URL('../images/flags/dj.svg', import.meta.url)
    },
    {
        country: "Dominica",
        countryCode: "DM",
        tags: ["Green", "Yellow", "Black", "White", "Red", "Parrot", "Stars", "America"],
        svgUrl: new URL('../images/flags/dm.svg', import.meta.url)
    },
    {
        country: "Dominican Republic",
        countryCode: "DO",
        tags: ["Red", "White", "Blue", "Coat of Arms", "Cross", "America"],
        svgUrl: new URL('../images/flags/do.svg', import.meta.url)
    },
    {
        country: "Ecuador",
        countryCode: "EC",
        tags: ["Yellow", "Blue", "Red", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/ec.svg', import.meta.url)
    },
    {
        country: "Egypt",
        countryCode: "EG",
        tags: ["Red", "White", "Black", "Eagle", "Africa"],
        svgUrl: new URL('../images/flags/eg.svg', import.meta.url)
    },
    {
        country: "El Salvador",
        countryCode: "SV",
        tags: ["Blue", "White", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/sv.svg', import.meta.url)
    },
    {
        country: "Equatorial Guinea",
        countryCode: "GQ",
        tags: ["Green", "White", "Red", "Blue", "Coat of Arms", "Africa"],
        svgUrl: new URL('../images/flags/gq.svg', import.meta.url)
    },
    {
        country: "Eritrea",
        countryCode: "ER",
        tags: ["Red", "Green", "Blue", "Yellow", "Olive Branch", "Triangle", "Africa"],
        svgUrl: new URL('../images/flags/er.svg', import.meta.url)
    },
    {
        country: "Estonia",
        countryCode: "EE",
        tags: ["Blue", "Black", "White", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/ee.svg', import.meta.url)
    },
    {
        country: "Eswatini",
        countryCode: "SZ",
        tags: ["Blue", "Yellow", "Red", "Black", "White", "Shield", "Spears", "Africa"],
        svgUrl: new URL('../images/flags/sz.svg', import.meta.url)
    },
    {
        country: "Ethiopia",
        countryCode: "ET",
        tags: ["Green", "Yellow", "Red", "Blue", "Star", "Africa"],
        svgUrl: new URL('../images/flags/et.svg', import.meta.url)
    },
    {
        country: "Fiji",
        countryCode: "FJ",
        tags: ["Light Blue", "Blue", "White", "Red", "Union Jack", "Shield", "Oceania"],
        svgUrl: new URL('../images/flags/fj.svg', import.meta.url)
    },
    {
        country: "Finland",
        countryCode: "FI",
        tags: ["Blue", "White", "Scandinavian Cross", "Europe"],
        svgUrl: new URL('../images/flags/fi.svg', import.meta.url)
    },
    {
        country: "France",
        countryCode: "FR",
        tags: ["Blue", "White", "Red", "Vertical Stripes", "Europe"],
        svgUrl: new URL('../images/flags/fr.svg', import.meta.url)
    },
    {
        country: "Gabon",
        countryCode: "GA",
        tags: ["Green", "Yellow", "Blue", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/ga.svg', import.meta.url)
    },
    {
        country: "The Gambia",
        countryCode: "GM",
        tags: ["Red", "Blue", "Green", "White", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/gm.svg', import.meta.url)
    },
    {
        country: "Georgia",
        countryCode: "GE",
        tags: ["White", "Red", "Cross", "Bolnur-Katskhuri Crosses", "Europe"],
        svgUrl: new URL('../images/flags/ge.svg', import.meta.url)
    },
    {
        country: "Germany",
        countryCode: "DE",
        tags: ["Black", "Red", "Gold", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/de.svg', import.meta.url)
    },
    {
        country: "Ghana",
        countryCode: "GH",
        tags: ["Red", "Yellow", "Green", "Black", "Star", "Africa"],
        svgUrl: new URL('../images/flags/gh.svg', import.meta.url)
    },
    {
        country: "Greece",
        countryCode: "GR",
        tags: ["Blue", "White", "Cross", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/gr.svg', import.meta.url)
    },
    {
        country: "Grenada",
        countryCode: "GD",
        tags: ["Red", "Yellow", "Green", "Stars", "Nutmeg", "America"],
        svgUrl: new URL('../images/flags/gd.svg', import.meta.url)
    },
    {
        country: "Guatemala",
        countryCode: "GT",
        tags: ["Blue", "White", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/gt.svg', import.meta.url)
    },
    {
        country: "Guinea",
        countryCode: "GN",
        tags: ["Red", "Yellow", "Green", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/gn.svg', import.meta.url)
    },
    {
        country: "Guinea-Bissau",
        countryCode: "GW",
        tags: ["Yellow", "Green", "Red", "Black", "Star", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/gw.svg', import.meta.url)
    },
    {
        country: "Guyana",
        countryCode: "GY",
        tags: ["Green", "Yellow", "White", "Red", "Black", "Triangle", "America"],
        svgUrl: new URL('../images/flags/gy.svg', import.meta.url)
    },
    {
        country: "Haiti",
        countryCode: "HT",
        tags: ["Blue", "Red", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/ht.svg', import.meta.url)
    },
    {
        country: "Honduras",
        countryCode: "HN",
        tags: ["Blue", "White", "Stars", "America"],
        svgUrl: new URL('../images/flags/hn.svg', import.meta.url)
    },
    {
        country: "Hungary",
        countryCode: "HU",
        tags: ["Red", "White", "Green", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/hu.svg', import.meta.url)
    },
    {
        country: "Iceland",
        countryCode: "IS",
        tags: ["Blue", "White", "Red", "Scandinavian Cross", "Europe"],
        svgUrl: new URL('../images/flags/is.svg', import.meta.url)
    },
    {
        country: "India",
        countryCode: "IN",
        tags: ["Orange", "White", "Green", "Blue", "Wheel", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/in.svg', import.meta.url)
    },
    {
        country: "Indonesia",
        countryCode: "ID",
        tags: ["Red", "White", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/id.svg', import.meta.url)
    },
    {
        country: "Iran",
        countryCode: "IR",
        tags: ["Green", "White", "Red", "Tulip", "Allah", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/ir.svg', import.meta.url)
    },
    {
        country: "Iraq",
        countryCode: "IQ",
        tags: ["Red", "White", "Black", "Green", "Arabic Script", "Middle East"],
        svgUrl: new URL('../images/flags/iq.svg', import.meta.url)
    },
    {
        country: "Ireland",
        countryCode: "IE",
        tags: ["Green", "White", "Orange", "Vertical Stripes", "Europe"],
        svgUrl: new URL('../images/flags/ie.svg', import.meta.url)
    },
    {
        country: "Israel",
        countryCode: "IL",
        tags: ["Blue", "White", "Star of David", "Middle East"],
        svgUrl: new URL('../images/flags/il.svg', import.meta.url)
    },
    {
        country: "Italy",
        countryCode: "IT",
        tags: ["Green", "White", "Red", "Vertical Stripes", "Europe"],
        svgUrl: new URL('../images/flags/it.svg', import.meta.url)
    },
    {
        country: "Jamaica",
        countryCode: "JM",
        tags: ["Green", "Yellow", "Black", "Diagonal Cross", "America"],
        svgUrl: new URL('../images/flags/jm.svg', import.meta.url)
    },
    {
        country: "Japan",
        countryCode: "JP",
        tags: ["White", "Red", "Circle", "Asia"],
        svgUrl: new URL('../images/flags/jp.svg', import.meta.url)
    },
    {
        country: "Jordan",
        countryCode: "JO",
        tags: ["Black", "White", "Green", "Red", "Triangle", "Star", "Middle East"],
        svgUrl: new URL('../images/flags/jo.svg', import.meta.url)
    },
    {
        country: "Kazakhstan",
        countryCode: "KZ",
        tags: ["Blue", "Yellow", "Sun", "Eagle", "Asia"],
        svgUrl: new URL('../images/flags/kz.svg', import.meta.url)
    },
    {
        country: "Kenya",
        countryCode: "KE",
        tags: ["Black", "Red", "Green", "White", "Shield", "Spears", "Africa"],
        svgUrl: new URL('../images/flags/ke.svg', import.meta.url)
    },
    {
        country: "Kiribati",
        countryCode: "KI",
        tags: ["Red", "White", "Blue", "Yellow", "Sun", "Bird", "Oceania"],
        svgUrl: new URL('../images/flags/ki.svg', import.meta.url)
    },
    {
        country: "Kosovo",
        countryCode: "XK",
        tags: ["Blue", "Yellow", "White", "Stars", "Map", "Europe"],
        svgUrl: new URL('../images/flags/xk.svg', import.meta.url)
    },
    {
        country: "Kuwait",
        countryCode: "KW",
        tags: ["Green", "White", "Red", "Black", "Horizontal Stripes", "Middle East"],
        svgUrl: new URL('../images/flags/kw.svg', import.meta.url)
    },
    {
        country: "Kyrgyzstan",
        countryCode: "KG",
        tags: ["Red", "Yellow", "Sun", "Tunduk", "Asia"],
        svgUrl: new URL('../images/flags/kg.svg', import.meta.url)
    },
    {
        country: "Laos",
        countryCode: "LA",
        tags: ["Red", "Blue", "White", "Circle", "Asia"],
        svgUrl: new URL('../images/flags/la.svg', import.meta.url)
    },
    {
        country: "Latvia",
        countryCode: "LV",
        tags: ["Red", "White", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/lv.svg', import.meta.url)
    },
    {
        country: "Lebanon",
        countryCode: "LB",
        tags: ["Red", "White", "Green", "Cedar Tree", "Middle East"],
        svgUrl: new URL('../images/flags/lb.svg', import.meta.url)
    },
    {
        country: "Lesotho",
        countryCode: "LS",
        tags: ["Blue", "White", "Green", "Black", "Hat", "Africa"],
        svgUrl: new URL('../images/flags/ls.svg', import.meta.url)
    },
    {
        country: "Liberia",
        countryCode: "LR",
        tags: ["Red", "White", "Blue", "Star", "Horizontal Stripes", "America"],
        svgUrl: new URL('../images/flags/lr.svg', import.meta.url)
    },
    {
        country: "Libya",
        countryCode: "LY",
        tags: ["Red", "Black", "Green", "White", "Crescent", "Star", "Middle East"],
        svgUrl: new URL('../images/flags/ly.svg', import.meta.url)
    },
    {
        country: "Liechtenstein",
        countryCode: "LI",
        tags: ["Blue", "Red", "Gold", "Crown", "Europe"],
        svgUrl: new URL('../images/flags/li.svg', import.meta.url)
    },
    {
        country: "Lithuania",
        countryCode: "LT",
        tags: ["Yellow", "Green", "Red", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/lt.svg', import.meta.url)
    },
    {
        country: "Luxembourg",
        countryCode: "LU",
        tags: ["Red", "White", "Light Blue", "Blue", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/lu.svg', import.meta.url)
    },
    {
        country: "Madagascar",
        countryCode: "MG",
        tags: ["Red", "Green", "White", "Vertical and Horizontal Stripes", "Vertical Stripes", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/mg.svg', import.meta.url)
    },
    {
        country: "Malawi",
        countryCode: "MW",
        tags: ["Black", "Red", "Green", "Sun", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/mw.svg', import.meta.url)
    },
    {
        country: "Malaysia",
        countryCode: "MY",
        tags: ["Red", "White", "Blue", "Yellow", "Crescent", "Star", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/my.svg', import.meta.url)
    },
    {
        country: "Maldives",
        countryCode: "MV",
        tags: ["Red", "Green", "White", "Crescent", "Asia"],
        svgUrl: new URL('../images/flags/mv.svg', import.meta.url)
    },
    {
        country: "Mali",
        countryCode: "ML",
        tags: ["Green", "Yellow", "Red", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/ml.svg', import.meta.url)
    },
    {
        country: "Malta",
        countryCode: "MT",
        tags: ["White", "Red", "George Cross", "Europe"],
        svgUrl: new URL('../images/flags/mt.svg', import.meta.url)
    },
    {
        country: "Marshall Islands",
        countryCode: "MH",
        tags: ["Blue", "White", "Orange", "Sun", "Stripe", "Oceania"],
        svgUrl: new URL('../images/flags/mh.svg', import.meta.url)
    },
    {
        country: "Mauritania",
        countryCode: "MR",
        tags: ["Green", "Red", "Yellow", "Crescent", "Star", "Africa"],
        svgUrl: new URL('../images/flags/mr.svg', import.meta.url)
    },
    {
        country: "Mauritius",
        countryCode: "MU",
        tags: ["Red", "Blue", "Yellow", "Green", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/mu.svg', import.meta.url)
    },
    {
        country: "Mexico",
        countryCode: "MX",
        tags: ["Green", "White", "Red", "Coat of Arms", "Vertical Stripes", "America"],
        svgUrl: new URL('../images/flags/mx.svg', import.meta.url)
    },
    {
        country: "Micronesia",
        countryCode: "FM",
        tags: ["Blue", "White", "Stars", "Oceania"],
        svgUrl: new URL('../images/flags/fm.svg', import.meta.url)
    },
    {
        country: "Moldova",
        countryCode: "MD",
        tags: ["Blue", "Yellow", "Red", "Coat of Arms", "Ox", "Europe"],
        svgUrl: new URL('../images/flags/md.svg', import.meta.url)
    },
    {
        country: "Monaco",
        countryCode: "MC",
        tags: ["Red", "White", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/mc.svg', import.meta.url)
    },
    {
        country: "Mongolia",
        countryCode: "MN",
        tags: ["Red", "Blue", "Soyombo Symbol", "Asia"],
        svgUrl: new URL('../images/flags/mn.svg', import.meta.url)
    },
    {
        country: "Montenegro",
        countryCode: "ME",
        tags: ["Red", "Gold", "Coat of Arms", "Double-Headed Eagle", "Europe"],
        svgUrl: new URL('../images/flags/me.svg', import.meta.url)
    },
    {
        country: "Morocco",
        countryCode: "MA",
        tags: ["Red", "Green", "Star", "Africa"],
        svgUrl: new URL('../images/flags/ma.svg', import.meta.url)
    },
    {
        country: "Mozambique",
        countryCode: "MZ",
        tags: ["Green", "Black", "Yellow", "Red", "White", "AK-47", "Star", "Book", "Africa"],
        svgUrl: new URL('../images/flags/mz.svg', import.meta.url)
    },
    {
        country: "Myanmar",
        countryCode: "MM",
        tags: ["Yellow", "Green", "Red", "White", "Star", "Asia"],
        svgUrl: new URL('../images/flags/mm.svg', import.meta.url)
    },
    {
        country: "Namibia",
        countryCode: "NA",
        tags: ["Blue", "Red", "Green", "White", "Sun", "Africa"],
        svgUrl: new URL('../images/flags/na.svg', import.meta.url)
    },
    {
        country: "Nauru",
        countryCode: "NR",
        tags: ["Blue", "Yellow", "White", "Star", "Oceania"],
        svgUrl: new URL('../images/flags/nr.svg', import.meta.url)
    },
    {
        country: "Nepal",
        countryCode: "NP",
        tags: ["Red", "Blue", "White", "Sun", "Moon", "Unique Shape", "Asia"],
        svgUrl: new URL('../images/flags/np.svg', import.meta.url)
    },
    {
        country: "Netherlands",
        countryCode: "NL",
        tags: ["Red", "White", "Blue", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/nl.svg', import.meta.url)
    },
    {
        country: "New Zealand",
        countryCode: "NZ",
        tags: ["Blue", "Red", "White", "Stars", "Union Jack", "Southern Cross", "Oceania"],
        svgUrl: new URL('../images/flags/nz.svg', import.meta.url)
    },
    {
        country: "Nicaragua",
        countryCode: "NI",
        tags: ["Blue", "White", "Coat of Arms", "Triangle", "America"],
        svgUrl: new URL('../images/flags/ni.svg', import.meta.url)
    },
    {
        country: "Niger",
        countryCode: "NE",
        tags: ["Orange", "White", "Green", "Orange Circle", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/ne.svg', import.meta.url)
    },
    {
        country: "Nigeria",
        countryCode: "NG",
        tags: ["Green", "White", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/ng.svg', import.meta.url)
    },
    {
        country: "North Korea",
        countryCode: "KP",
        tags: ["Red", "White", "Blue", "Star", "Circle", "Asia"],
        svgUrl: new URL('../images/flags/kp.svg', import.meta.url)
    },
    {
        country: "North Macedonia",
        countryCode: "MK",
        tags: ["Red", "Yellow", "Sun", "Eight Rays", "Europe"],
        svgUrl: new URL('../images/flags/mk.svg', import.meta.url)
    },
    {
        country: "Norway",
        countryCode: "NO",
        tags: ["Red", "White", "Blue", "Scandinavian Cross", "Europe"],
        svgUrl: new URL('../images/flags/no.svg', import.meta.url)
    },
    {
        country: "Oman",
        countryCode: "OM",
        tags: ["Red", "White", "Green", "Dagger", "Belt", "Middle East"],
        svgUrl: new URL('../images/flags/om.svg', import.meta.url)
    },
    {
        country: "Pakistan",
        countryCode: "PK",
        tags: ["Green", "White", "Crescent", "Star", "Asia"],
        svgUrl: new URL('../images/flags/pk.svg', import.meta.url)
    },
    {
        country: "Palau",
        countryCode: "PW",
        tags: ["Light Blue", "Blue", "Yellow", "Circle", "Oceania"],
        svgUrl: new URL('../images/flags/pw.svg', import.meta.url)
    },
    {
        country: "Palestine",
        countryCode: "PS",
        tags: ["Red", "White", "Green", "Black", "Triangle", "Horizontal Stripes", "Middle East"],
        svgUrl: new URL('../images/flags/ps.svg', import.meta.url)
    },
    {
        country: "Panama",
        countryCode: "PA",
        tags: ["Red", "White", "Blue", "Stars", "America"],
        svgUrl: new URL('../images/flags/pa.svg', import.meta.url)
    },
    {
        country: "Papua New Guinea",
        countryCode: "PG",
        tags: ["Red", "Black", "Yellow", "Bird of Paradise", "Southern Cross", "Oceania"],
        svgUrl: new URL('../images/flags/pg.svg', import.meta.url)
    },
    {
        country: "Paraguay",
        countryCode: "PY",
        tags: ["Red", "White", "Blue", "Coat of Arms", "America"],
        svgUrl: new URL('../images/flags/py.svg', import.meta.url)
    },
    {
        country: "Peru",
        countryCode: "PE",
        tags: ["Red", "White", "Vertical Stripes", "America"],
        svgUrl: new URL('../images/flags/pe.svg', import.meta.url)
    },
    {
        country: "Philippines",
        countryCode: "PH",
        tags: ["Blue", "Red", "White", "Yellow", "Sun", "Stars", "Triangle", "Asia"],
        svgUrl: new URL('../images/flags/ph.svg', import.meta.url)
    },
    {
        country: "Poland",
        countryCode: "PL",
        tags: ["White", "Red", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/pl.svg', import.meta.url)
    },
    {
        country: "Portugal",
        countryCode: "PT",
        tags: ["Green", "Red", "Coat of Arms", "Europe"],
        svgUrl: new URL('../images/flags/pt.svg', import.meta.url)
    },
    {
        country: "Qatar",
        countryCode: "QA",
        tags: ["Maroon", "White", "Serrated Border", "Middle East"],
        svgUrl: new URL('../images/flags/qa.svg', import.meta.url)
    },
    {
        country: "Republic of the Congo",
        countryCode: "CG",
        tags: ["Green", "Yellow", "Red", "Diagonal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/cg.svg', import.meta.url)
    },
    {
        country: "Romania",
        countryCode: "RO",
        tags: ["Blue", "Yellow", "Red", "Vertical Stripes", "Europe"],
        svgUrl: new URL('../images/flags/ro.svg', import.meta.url)
    },
    {
        country: "Russia",
        countryCode: "RU",
        tags: ["White", "Blue", "Red", "Horizontal Stripes", "Europe", "Asia"],
        svgUrl: new URL('../images/flags/ru.svg', import.meta.url)
    },
    {
        country: "Rwanda",
        countryCode: "RW",
        tags: ["Blue", "Yellow", "Green", "Sun", "Africa"],
        svgUrl: new URL('../images/flags/rw.svg', import.meta.url)
    },
    {
        country: "Saint Kitts and Nevis",
        countryCode: "KN",
        tags: ["Green", "Red", "Black", "White", "Stars", "Diagonal Stripes", "America"],
        svgUrl: new URL('../images/flags/kn.svg', import.meta.url)
    },
    {
        country: "Saint Lucia",
        countryCode: "LC",
        tags: ["Blue", "Black", "White", "Yellow", "Triangle", "America"],
        svgUrl: new URL('../images/flags/lc.svg', import.meta.url)
    },
    {
        country: "Saint Vincent and the Grenadines",
        countryCode: "VC",
        tags: ["Blue", "Green", "Yellow", "Gems", "America"],
        svgUrl: new URL('../images/flags/vc.svg', import.meta.url)
    },
    {
        country: "Samoa",
        countryCode: "WS",
        tags: ["Red", "Blue", "White", "Southern Cross", "Oceania"],
        svgUrl: new URL('../images/flags/ws.svg', import.meta.url)
    },
    {
        country: "San Marino",
        countryCode: "SM",
        tags: ["Blue", "White", "Coat of Arms", "Europe"],
        svgUrl: new URL('../images/flags/sm.svg', import.meta.url)
    },
    {
        country: "São Tomé and Príncipe",
        countryCode: "ST",
        tags: ["Green", "Yellow", "Red", "Black", "Stars", "Africa"],
        svgUrl: new URL('../images/flags/st.svg', import.meta.url)
    },
    {
        country: "Saudi Arabia",
        countryCode: "SA",
        tags: ["Green", "White", "Arabic Script", "Sword", "Middle East"],
        svgUrl: new URL('../images/flags/sa.svg', import.meta.url)
    },
    {
        country: "Senegal",
        countryCode: "SN",
        tags: ["Green", "Yellow", "Red", "Star", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/sn.svg', import.meta.url)
    },
    {
        country: "Serbia",
        countryCode: "RS",
        tags: ["Red", "Blue", "White", "Coat of Arms", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/rs.svg', import.meta.url)
    },
    {
        country: "Seychelles",
        countryCode: "SC",
        tags: ["Blue", "Yellow", "Red", "White", "Green", "Radiant", "Africa"],
        svgUrl: new URL('../images/flags/sc.svg', import.meta.url)
    },
    {
        country: "Sierra Leone",
        countryCode: "SL",
        tags: ["Green", "White", "Blue", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/sl.svg', import.meta.url)
    },
    {
        country: "Singapore",
        countryCode: "SG",
        tags: ["Red", "White", "Crescent", "Stars", "Asia"],
        svgUrl: new URL('../images/flags/sg.svg', import.meta.url)
    },
    {
        country: "Slovakia",
        countryCode: "SK",
        tags: ["White", "Blue", "Red", "Coat of Arms", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/sk.svg', import.meta.url)
    },
    {
        country: "Slovenia",
        countryCode: "SI",
        tags: ["White", "Blue", "Red", "Coat of Arms", "Three Peaks", "Europe"],
        svgUrl: new URL('../images/flags/si.svg', import.meta.url)
    },
    {
        country: "Solomon Islands",
        countryCode: "SB",
        tags: ["Blue", "Green", "Yellow", "White", "Stars", "Oceania"],
        svgUrl: new URL('../images/flags/sb.svg', import.meta.url)
    },
    {
        country: "Somalia",
        countryCode: "SO",
        tags: ["Light Blue", "Blue", "White", "Star", "Middle East"],
        svgUrl: new URL('../images/flags/so.svg', import.meta.url)
    },
    {
        country: "South Africa",
        countryCode: "ZA",
        tags: ["Black", "Green", "Yellow", "White", "Red", "Blue", "Y-Shape", "Africa"],
        svgUrl: new URL('../images/flags/za.svg', import.meta.url)
    },
    {
        country: "South Korea",
        countryCode: "KR",
        tags: ["White", "Red", "Blue", "Black", "Taeguk", "Trigrams", "Asia"],
        svgUrl: new URL('../images/flags/kr.svg', import.meta.url)
    },
    {
        country: "South Sudan",
        countryCode: "SS",
        tags: ["Black", "Red", "Green", "Blue", "Yellow", "Star", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/ss.svg', import.meta.url)
    },
    {
        country: "Spain",
        countryCode: "ES",
        tags: ["Red", "Yellow", "Coat of Arms", "Europe"],
        svgUrl: new URL('../images/flags/es.svg', import.meta.url)
    },
    {
        country: "Sri Lanka",
        countryCode: "LK",
        tags: ["Maroon", "Gold", "Green", "Orange", "Leaves", "Lion", "Asia"],
        svgUrl: new URL('../images/flags/lk.svg', import.meta.url)
    },
    {
        country: "Sudan",
        countryCode: "SD",
        tags: ["Red", "White", "Black", "Green", "Triangle", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/sd.svg', import.meta.url)
    },
    {
        country: "Suriname",
        countryCode: "SR",
        tags: ["Green", "White", "Red", "Yellow", "Star", "America"],
        svgUrl: new URL('../images/flags/sr.svg', import.meta.url)
    },
    {
        country: "Sweden",
        countryCode: "SE",
        tags: ["Blue", "Yellow", "Scandinavian Cross", "Europe"],
        svgUrl: new URL('../images/flags/se.svg', import.meta.url)
    },
    {
        country: "Switzerland",
        countryCode: "CH",
        tags: ["Red", "White", "Cross", "Europe"],
        svgUrl: new URL('../images/flags/ch.svg', import.meta.url)
    },
    {
        country: "Syria",
        countryCode: "SY",
        tags: ["Red", "White", "Black", "Green", "Stars", "Horizontal Stripes", "Middle East"],
        svgUrl: new URL('../images/flags/sy.svg', import.meta.url)
    },
    {
        country: "Taiwan",
        countryCode: "TW",
        tags: ["Red", "Blue", "White", "Sun", "Asia"],
        svgUrl: new URL('../images/flags/tw.svg', import.meta.url)
    },
    {
        country: "Tajikistan",
        countryCode: "TJ",
        tags: ["Red", "White", "Green", "Crown", "Stars", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/tj.svg', import.meta.url)
    },
    {
        country: "Tanzania",
        countryCode: "TZ",
        tags: ["Green", "Yellow", "Black", "Blue", "Diagonal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/tz.svg', import.meta.url)
    },
    {
        country: "Thailand",
        countryCode: "TH",
        tags: ["Red", "White", "Blue", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/th.svg', import.meta.url)
    },
    {
        country: "Timor-Leste",
        countryCode: "TL",
        tags: ["Red", "Yellow", "Black", "White", "Star", "Triangle", "Asia"],
        svgUrl: new URL('../images/flags/tl.svg', import.meta.url)
    },
    {
        country: "Togo",
        countryCode: "TG",
        tags: ["Green", "Yellow", "Red", "White", "Star", "Vertical Stripes", "Africa"],
        svgUrl: new URL('../images/flags/tg.svg', import.meta.url)
    },
    {
        country: "Tonga",
        countryCode: "TO",
        tags: ["Red", "White", "Cross", "Oceania"],
        svgUrl: new URL('../images/flags/to.svg', import.meta.url)
    },
    {
        country: "Trinidad and Tobago",
        countryCode: "TT",
        tags: ["Red", "Black", "White", "Diagonal Stripe", "America"],
        svgUrl: new URL('../images/flags/tt.svg', import.meta.url)
    },
    {
        country: "Tunisia",
        countryCode: "TN",
        tags: ["Red", "White", "Crescent", "Star", "Africa"],
        svgUrl: new URL('../images/flags/tn.svg', import.meta.url)
    },
    {
        country: "Türkiye",
        countryCode: "TR",
        tags: ["Red", "White", "Crescent", "Star", "Asia", "Europe"],
        svgUrl: new URL('../images/flags/tr.svg', import.meta.url)
    },
    {
        country: "Turkmenistan",
        countryCode: "TM",
        tags: ["Green", "White", "Red", "Crescent", "Stars", "Carpet Patterns", "Asia"],
        svgUrl: new URL('../images/flags/tm.svg', import.meta.url)
    },
    {
        country: "Tuvalu",
        countryCode: "TV",
        tags: ["Light Blue", "Blue", "Yellow", "White", "Stars", "Union Jack", "Oceania"],
        svgUrl: new URL('../images/flags/tv.svg', import.meta.url)
    },
    {
        country: "Uganda",
        countryCode: "UG",
        tags: ["Black", "Yellow", "Red", "White", "Bird", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/ug.svg', import.meta.url)
    },
    {
        country: "Ukraine",
        countryCode: "UA",
        tags: ["Blue", "Yellow", "Horizontal Stripes", "Europe"],
        svgUrl: new URL('../images/flags/ua.svg', import.meta.url)
    },
    {
        country: "United Arab Emirates",
        countryCode: "AE",
        tags: ["Green", "White", "Black", "Red", "Vertical Stripe", "Middle East"],
        svgUrl: new URL('../images/flags/ae.svg', import.meta.url)
    },
    {
        country: "United Kingdom",
        countryCode: "GB",
        tags: ["Red", "White", "Blue", "Union Jack", "Europe"],
        svgUrl: new URL('../images/flags/gb.svg', import.meta.url)
    },
    {
        country: "United States of America",
        countryCode: "US",
        tags: ["Red", "White", "Blue", "Stars", "Stripes", "North America"],
        svgUrl: new URL('../images/flags/us.svg', import.meta.url)
    },
    {
        country: "Uruguay",
        countryCode: "UY",
        tags: ["Blue", "White", "Yellow", "Sun", "Horizontal Stripes", "America"],
        svgUrl: new URL('../images/flags/uy.svg', import.meta.url)
    },
    {
        country: "Uzbekistan",
        countryCode: "UZ",
        tags: ["Blue", "White", "Green", "Red", "Crescent", "Stars", "Horizontal Stripes", "Asia"],
        svgUrl: new URL('../images/flags/uz.svg', import.meta.url)
    },
    {
        country: "Vanuatu",
        countryCode: "VU",
        tags: ["Red", "Green", "Black", "Yellow", "Tusk", "Leaves", "Oceania"],
        svgUrl: new URL('../images/flags/vu.svg', import.meta.url)
    },
    {
        country: "Vatican City",
        countryCode: "VA",
        tags: ["Gold", "White", "Keys", "Tiara", "Europe"],
        svgUrl: new URL('../images/flags/va.svg', import.meta.url)
    },
    {
        country: "Venezuela",
        countryCode: "VE",
        tags: ["Yellow", "Blue", "Red", "Stars", "Coat of Arms", "Horizontal Stripes", "America"],
        svgUrl: new URL('../images/flags/ve.svg', import.meta.url)
    },
    {
        country: "Vietnam",
        countryCode: "VN",
        tags: ["Red", "Yellow", "Star", "Asia"],
        svgUrl: new URL('../images/flags/vn.svg', import.meta.url)
    },
    {
        country: "Yemen",
        countryCode: "YE",
        tags: ["Red", "White", "Black", "Horizontal Stripes", "Middle East"],
        svgUrl: new URL('../images/flags/ye.svg', import.meta.url)
    },
    {
        country: "Zambia",
        countryCode: "ZM",
        tags: ["Green", "Red", "Black", "Orange", "Eagle", "Vertical Stripe", "Africa"],
        svgUrl: new URL('../images/flags/zm.svg', import.meta.url)
    },
    {
        country: "Zimbabwe",
        countryCode: "ZW",
        tags: ["Green", "Yellow", "Red", "Black", "White", "Star", "Bird", "Horizontal Stripes", "Africa"],
        svgUrl: new URL('../images/flags/zw.svg', import.meta.url)
    },
]
